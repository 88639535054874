export default {
  title: {
    id: 'insurance_cart_title',
    defaultMessage:
      'Make your {deviceName, html} (almost) immortal with our coverage.',
  },
  recommended: {
    id: 'insurance_cart_recommended_tag',
    defaultMessage: 'Recommended',
  },
  monthlyPayment: {
    id: 'insurance_cart_monthly_payment_tag',
    defaultMessage: 'Monthly payment',
  },
  protectedWith: {
    id: 'insurance_cart_protected_with',
    defaultMessage: 'Protected with {logo, html}',
  },
  learnMore: {
    id: 'insurance_cart_learn_more',
    defaultMessage: 'Learn more about',
  },
}
