<template>
  <div class="space-y-24 md:space-y-24">
    <div class="text-static-default-low flex flex-col space-y-12">
      <div v-if="!withoutTitle" class="text-static-default-hi body-1">
        <FormattedMessage :definition="translations.title">
          <template #deviceName>
            <span class="body-1-bold"> {{ productName }}</span>
          </template>
        </FormattedMessage>
      </div>

      <div class="mb-12 flex flex-col md:flex-row">
        <div
          v-for="(offer, index) in offers.filter(
            (offer) => !isDefaultOffer(offer),
          )"
          :key="`insurance_${listingId}_${offer.id}`"
          class="relative flex-1 first:mb-12 md:first:mb-0 md:first:mr-12"
        >
          <RevTag
            v-if="
              offer.isMonthly && market.countryCode === MarketCountryCode.US
            "
            class="absolute -top-8 right-16 z-[1]"
            :label="i18n(translations.monthlyPayment)"
            variant="primary"
          />
          <template v-else>
            <RevTag
              v-if="index === 0 && !hasMonthlyTag"
              class="absolute -top-8 right-16 z-[1]"
              :label="i18n(translations.recommended)"
              variant="primary"
            />
          </template>
          <div
            class="rounded-l-lg absolute z-[1] h-full w-[6px]"
            :class="
              index === 0
                ? 'bg-static-success-low'
                : 'bg-action-default-hi opacity-60'
            "
          />
          <RevRadio
            :id="`insurance_${listingId}_${offer.id}`"
            :class="['h-full', $style.radioLabelHeight]"
            :data-qa="`insurance-offer-${index}`"
            :model-value="selectedOffer.id === offer.id"
            :name="`insuranceOffer_${listingId}_${offer.id}`"
            :value="`${offer.id}`"
            variant="full"
            @click="() => handleOfferRadioCheck(offer)"
          >
            <template #label>
              <div>
                <span class="body-1-bold">
                  {{ offer.title }}
                </span>

                <template v-if="offer.price && offer.price.amount !== '0.00'">
                  <InsurancePrice
                    v-if="isMonthlyInsuranceEnabled"
                    :is-monthly="offer.isMonthly"
                    :price="offer.price"
                  />

                  <span v-else class="body-1 ml-4">
                    {{ `• ${i18n.price(offer.price)}` }}
                  </span>
                </template>
              </div>
            </template>
            <template #description>
              <Benefits
                :benefits="offer.benefits.short"
                class="mt-8"
                :tracking="{
                  name: 'show_benefits',
                  zone: trackingZone,
                  value: offer.title,
                }"
              />
            </template>
          </RevRadio>
        </div>
      </div>

      <div>
        <div class="flex flex-row items-center justify-between">
          <FormattedMessage
            class="body-2 flex h-16 items-center"
            :definition="translations.protectedWith"
            tag="div"
          >
            <template #logo>
              <RevIllustration
                alt="BackUp logo"
                class="ml-4 mt-1"
                :height="16"
                src="/img/insurances/backUpLogo.svg"
                :width="72"
              />
            </template>
          </FormattedMessage>

          <div>
            <RevLink class="body-2-link" @click="handleInsuranceModalOpen">
              {{ i18n(translations.learnMore) }}
            </RevLink>

            <ClientOnly>
              <InsuranceModal :name="modalName" />
            </ClientOnly>
          </div>
        </div>
      </div>
    </div>

    <div v-if="!selectedOffer.defaultOffer" class="relative">
      <RevCheckbox
        v-if="selectedOffer.compliancy.isUserAgreementRequired"
        :id="`compliancy-${listingId}`"
        :checked="props.selectedOffer.compliancy.isUserAgreementAccepted"
        @change="handleCompliancyCheckboxUpdate"
      >
        <template #label>
          <Terms :documents="documents" />
        </template>
      </RevCheckbox>

      <Terms v-else :documents="documents" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { MarketCountryCode } from '@backmarket/http-api'
import type { CheckoutInsuranceOffer } from '@backmarket/http-api/src/api-specs-checkout/cart/cart.types'
import type { InsurancePostPurchaseOffers } from '@backmarket/http-api/src/api-specs-checkout/checkout/ShoppingSession.types'
import { useExperiments } from '@backmarket/nuxt-module-experiments/useExperiments'
import FormattedMessage from '@backmarket/nuxt-module-i18n/FormattedMessage.vue'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { RevCheckbox } from '@ds/components/Checkbox'
import { RevIllustration } from '@ds/components/Illustration'
import { RevLink } from '@ds/components/Link'
import { openModal } from '@ds/components/ModalBase'
import { RevRadio } from '@ds/components/Radio'
import { RevTag } from '@ds/components/Tag'
import type { EventFromInput } from '@ds/types/EventFromInput'

import { MODAL_NAMES } from '../../config/constants'
import Terms from '../Terms/Terms.vue'

import Benefits from './Benefits/Benefits.vue'
import InsuranceModal from './InsuranceModal/InsuranceModal.vue'
import InsurancePrice from './InsurancePrice/InsurancePrice.vue'
import translations from './InsurancesOptions.translations'

type InsuranceOffer = CheckoutInsuranceOffer | InsurancePostPurchaseOffers

const props = withDefaults(
  defineProps<{
    listingId: string
    offers: InsuranceOffer[]
    productName?: string
    showSelected: boolean
    withoutTitle?: boolean
    canBeDeselected?: boolean
    trackingZone: string
    selectedOffer: InsuranceOffer
  }>(),
  {
    productName: '',
    withoutTitle: false,
    canBeDeselected: false,
  },
)

const emit = defineEmits<{
  'update-compliancy': [udpatedOffer: InsuranceOffer]
  'update-selected-option': [updatedOffer: InsuranceOffer]
}>()

const { market } = useMarketplace()

const experiments = useExperiments()
const i18n = useI18n()
const { trackClick } = useTracking()

function isDefaultOffer(offer: InsuranceOffer) {
  return offer.defaultOffer
}

const modalName = `${MODAL_NAMES.CART_INSURANCE_OFFER_EXPLANATIONS}-${props.listingId}`

const isMonthlyInsuranceEnabled = computed(
  () =>
    experiments['experiment.insuranceMonthlySubscription'] ===
    'insuranceMonthlyEnabled',
)

function shouldDisplayMonthlyTag(offer: InsuranceOffer) {
  return offer.isMonthly && market.countryCode === MarketCountryCode.US
}

const hasMonthlyTag = computed(() => props.offers.some(shouldDisplayMonthlyTag))

const documents = computed(() => props.selectedOffer.compliancy.documents)

const handleOfferRadioCheck = (offer: InsuranceOffer) => {
  const defaultOffer = props.offers.find(isDefaultOffer)

  const isUnselecting =
    props.canBeDeselected && props.selectedOffer.id === offer.id
  const selectedOffer = isUnselecting ? defaultOffer || offer : offer

  emit('update-selected-option', { ...selectedOffer, selected: true })

  trackClick({
    name: selectedOffer.title,
    value: selectedOffer.isMonthly ? 'monthly' : 'upfront',
    zone: props.trackingZone,
  })
}

function handleCompliancyCheckboxUpdate(e: EventFromInput) {
  const updatedInsurance = {
    ...props.selectedOffer,
    compliancy: {
      ...props.selectedOffer.compliancy,
      isUserAgreementAccepted: e.target.checked,
    },
  }

  emit('update-compliancy', updatedInsurance)
}

const handleInsuranceModalOpen = () => {
  openModal(modalName)
  trackClick({
    name: 'how_does_it_work',
    zone: props.trackingZone,
  })
}
</script>

<style module>
.radioLabelHeight > label {
  height: 100%;
}
</style>
